import { api, ApiResponseTypes, GameModeTypes } from 'services'

const postGameMode = async () => {
  const { data: response } =
    await api.post<ApiResponseTypes<GameModeTypes>>('/gameModes')

  return response.data
}

export default {
  post: postGameMode
}
