import { GameTypes } from 'services'
import { InvoiceStatusTypes } from 'views/customerOverview/components'

import { StatusTypes } from 'components'

export const ASSIGNMENTS_ROWS_VALUE = [
  {
    id: 0,
    title: 'Carlos Rodrigues',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 1,
    title: 'Flavio Andrade',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 2,
    title: 'Isaque Souza',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 3,
    title: 'Alexandre Rodrigues',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.COMPLETED,
    progress: '50'
  },
  {
    id: 4,
    title: 'Santiago Vicente',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 5,
    title: 'Tyler Deren',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 6,
    title: 'Germán Barros',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 7,
    title: 'Kristoffer Engebø',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 8,
    title: 'Patrick Holleman',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 9,
    title: 'Ådne Olsen',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 10,
    title: 'Petter Blandhol',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 11,
    title: 'Jørgen H. Strand',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.PLANNED,
    progress: '50'
  },
  {
    id: 12,
    title: 'Alexandre Rodrigues',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.PLANNED,
    progress: '50'
  },
  {
    id: 13,
    title: 'Gustavo Silva',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  },
  {
    id: 14,
    title: 'Weslley Marque',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.PLANNED,
    progress: '50'
  },
  {
    id: 15,
    title: 'Petter Askeland',
    endDate: new Date('01/01/2022'),
    assignedToGroups: [],
    assignedToStudents: [],
    startDate: new Date('01/01/2022'),
    status: StatusTypes.ACTIVE,
    progress: '50'
  }
]

export const STUDENTS_ROWS_VALUE = [
  {
    id: '0',
    name: 'Allison Silva',
    user_group: {
      name: 'alex'
    },
    parentsName: 'dsa.vasset@gmail.com'
  },
  {
    id: '1',
    name: 'Richard Gonzalez',
    user_group: null,
    parentsName: 'richard.gonzalez@gmail.com'
  },
  {
    id: '2',
    name: 'Claire Dickson',
    user_group: null,
    parentsName: 'gdshxch@gmail.com'
  },
  {
    id: '3',
    name: 'David Laide',
    user_group: {
      name: 'Grade 1'
    },
    parentsName: 'ojhhjhj@gmail.com'
  },
  {
    id: '4',
    name: 'Carlos Rodrigues',
    user_group: {
      name: 'Grade 6'
    },
    parentsName: ''
  }
]

export const SEARCH_ITEMS = [
  'Class activity challenge #1',
  'Class math',
  'Class geography',
  'Class chemistry'
]

export const reportDataList = [
  {
    id: '0',
    title: 'Lekse',
    startDate: '2022-03-21T00:00:00.000Z',
    endDate: '2022-03-28T00:00:00.000Z',
    groups: [
      {
        name: 'Class 5 C'
      }
    ],
    score: 450,
    timeSpent: '01:10:50',
    failedAnswers: 4,
    progress: '50'
  }
]

export const subColumnsDataList = reportDataList.map(
  ({ title: _, ...rest }) => ({
    game: 'Game name',
    ...rest
  })
)

export const gameList: GameTypes[] = [
  {
    id: 0,
    isActive: true,
    name: 'Access Shaft',
    funFact: 'The game is based on the movie "The Matrix"',
    planet: {
      id: 0,
      name: 'Envoy'
    },
    image: '',
    helpArticleUrl: '',
    preClassroomDescription: `
    Game elements:             \n            In Access Shaft, the students will work with odd numbers, even numbers, digits, values and the place value system.             \n            Odd and even numbers:            \n
    `,
    shortDescription:
      'Word problems covering odd numbers, even numbers, digits and place value.',
    longDescription:
      'In Access Shaft, the students must hack through the Envoy spaceship doors by solving codes, including numbers, digits, equal numbers, odd numbers and place value up to the thousands place.',
    topics: [
      {
        id: 1,
        name: 'Number'
      }
    ],
    tags: [
      {
        id: 88,
        name: 'Odd numbers'
      },
      {
        id: 91,
        name: 'Place value'
      },
      {
        id: 90,
        name: 'Digits'
      },
      {
        id: 89,
        name: 'Even numbers'
      }
    ],
    curriculum: {
      title:
        'Develop and use different strategies for calculations with positive numbers and fractions and explain their ways of thinking.'
    },
    gameModes: [
      {
        id: 0,
        playTime: '00:06:00',
        boards: 21,
        description:
          'The students will be read instructions on how to solve door codes of up to four digits. The students practice even numbers, odd numbers, digits and place values. These are word problems with few elements to be solved at a time.',
        tags: [
          {
            id: 89,
            name: 'Even numbers'
          },
          {
            id: 88,
            name: 'Odd numbers'
          }
        ],
        mode: {
          id: 4,
          name: 'Core mode',
          iconName: 'bolt',
          iconQuantity: 1,
          shortName: '',
          order: 1
        }
      },
      {
        id: 1,
        playTime: '00:14:00',
        boards: 13,
        description:
          'The students must take in more information. They must create a number as close as possible to a certain number, where they demonstrate competence concerning even numbers and odd numbers and also numeracy skills. Alle instructions are read aloud.',
        tags: [],
        mode: {
          id: 2,
          name: 'Challenge 1',
          iconQuantity: 2,
          shortName: '',
          iconName: 'bolt',
          order: 3
        }
      },
      {
        id: 2,
        playTime: '00:09:00',
        boards: 10,
        description:
          'The difficulty level increases further. In addition to even numbers, odd numbers and the place value system, the students will deal with terms like highest, lowest, next highest and next lowest.',
        tags: [],
        mode: {
          id: 3,
          name: 'Challenge 2',
          iconName: null,
          iconQuantity: null,
          shortName: '',
          order: 4
        }
      }
    ]
  }
]

export const licenseList = [
  {
    id: 33,
    feideId: 'fa8079db-8d5f-47fd-a6c6-c383d7e82274',
    name: 'laerer_no856326499_8a_1 Testbruker',
    role: 'Teacher',
    groups:
      'Basisgruppe 1A, Basisgruppe 1A, Basisgruppe 4A, Basisgruppe 4A, Basisgruppe 8A, Basisgruppe 8A, Ekstrahjelp, Ekstrahjelp, Engelsk 4A, Engelsk 4A, Engelsk 8A, Engelsk 8A, Matematikk 5 A, Matematikk 5 A, Matematikk 8A, Matematikk 8A, Naturfag 4A, Naturfag 4A, Norsk 4A, Norsk 4A, Norsk 8A, Norsk 8A, Samfunnsfag 1A, Samfunnsfag 1A, Samfunnsfag 4A, Samfunnsfag 4A, Sterke elever, Sterke elever, test, test, Testing, Testing',
    expiryDate: '2022-08-10T00:00:00.000Z',
    hasActiveLicense: true
  },
  {
    id: 24,
    feideId: 'e6249030-d33c-46f7-a57a-3fd1784e0a6c',
    name: 'laerer_no856326499_1a_1 Testbruker',
    role: 'Teacher',
    groups:
      'Basisgruppe 1A, Basisgruppe 1A, Basisgruppe 4A, Basisgruppe 4A, Basisgruppe 8A, Basisgruppe 8A, Ekstrahjelp, Ekstrahjelp, Engelsk 4A, Engelsk 4A, Engelsk 8A, Engelsk 8A, Matematikk 5 A, Matematikk 5 A, Matematikk 8A, Matematikk 8A, Naturfag 4A, Naturfag 4A, Norsk 4A, Norsk 4A, Norsk 8A, Norsk 8A, Samfunnsfag 1A, Samfunnsfag 1A, Samfunnsfag 4A, Samfunnsfag 4A, Sterke elever, Sterke elever, test, test, Testing, Testing',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 31,
    feideId: 'e6249030-d33c-46f7-a57a-3fd1784e0a6c',
    name: 'laerer_no856326499_1a_1 Testbruker',
    role: 'Teacher',
    groups:
      'Basisgruppe 1A, Basisgruppe 1A, Basisgruppe 4A, Basisgruppe 4A, Basisgruppe 8A, Basisgruppe 8A, Ekstrahjelp, Ekstrahjelp, Engelsk 4A, Engelsk 4A, Engelsk 8A, Engelsk 8A, Matematikk 5 A, Matematikk 5 A, Matematikk 8A, Matematikk 8A, Naturfag 4A, Naturfag 4A, Norsk 4A, Norsk 4A, Norsk 8A, Norsk 8A, Samfunnsfag 1A, Samfunnsfag 1A, Samfunnsfag 4A, Samfunnsfag 4A, Sterke elever, Sterke elever, test, test, Testing, Testing',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 36,
    feideId: '68a60e33-f910-4c28-aa30-a01ed40de684',
    name: 'laerer_no856326499_4a_1 Testbruker',
    role: 'Teacher',
    groups:
      'Basisgruppe 1A, Basisgruppe 1A, Basisgruppe 4A, Basisgruppe 4A, Basisgruppe 8A, Basisgruppe 8A, Ekstrahjelp, Ekstrahjelp, Engelsk 4A, Engelsk 4A, Engelsk 8A, Engelsk 8A, Matematikk 5 A, Matematikk 5 A, Matematikk 8A, Matematikk 8A, Naturfag 4A, Naturfag 4A, Norsk 4A, Norsk 4A, Norsk 8A, Norsk 8A, Samfunnsfag 1A, Samfunnsfag 1A, Samfunnsfag 4A, Samfunnsfag 4A, Sterke elever, Sterke elever, test, test, Testing, Testing',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2921,
    feideId: null,
    name: 'elev_no856326499_8a_7 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 806,
    feideId: 'e6249030-d33c-46f7-a57a-3fd1784e0a6c',
    name: 'laerer_no856326499_1a_1 Testbruker',
    role: 'Student',
    groups: 'Basisgruppe 1A, Samfunnsfag 1A, Testing',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2919,
    feideId: null,
    name: 'elev_no856326499_8a_5 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2963,
    feideId: '5053dc55-10d8-4a33-877b-14fc0db510dc',
    name: 'laerer_no856326499_4a_1 Testbruker',
    role: 'Student',
    groups: 'Basisgruppe 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2915,
    feideId: null,
    name: 'elev_no856326499_8a_1 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Ekstrahjelp, Engelsk 8A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2916,
    feideId: null,
    name: 'elev_no856326499_8a_2 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 5 A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2920,
    feideId: null,
    name: 'elev_no856326499_8a_6 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 2917,
    feideId: null,
    name: 'elev_no856326499_8a_3 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 5 A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  },
  {
    id: 804,
    feideId: 'fa8079db-8d5f-47fd-a6c6-c383d7e82274',
    name: 'laerer_no856326499_8a_1 Testbruker',
    role: 'Student',
    groups: null,
    expiryDate: '2022-08-10T00:00:00.000Z',
    hasActiveLicense: true
  },
  {
    id: 2918,
    feideId: null,
    name: 'elev_no856326499_8a_4 Testbruker',
    role: 'Student',
    groups:
      'Basisgruppe 8A, Basisgruppe 8A, Engelsk 8A, Matematikk 5 A, Matematikk 8A, Norsk 8A',
    hasActiveLicense: false,
    expiryDate: '2022-08-10T00:00:00.000Z'
  }
]

export const reportStudentsList = [
  {
    id: '0fd3f02d-e121-4528-af4a-c1273c8582a2',
    name: 'Carlos A.',
    startDate: '2022-03-21T00:00:00.000Z',
    endDate: '2022-03-28T00:00:00.000Z',

    score: 450,
    timeSpent: '01:10:50',
    failedAnswers: 4,
    progress: '50'
  },
  {
    id: '3083cf92-fd52-495c-a763-4adc8b7da5e2',
    name: 'Isaque S.',
    startDate: '2022-03-11T00:00:00.000Z',
    endDate: '2022-03-18T00:00:00.000Z',

    score: 250,
    timeSpent: '01:10:50',
    failedAnswers: 8,
    progress: '50'
  },
  {
    id: '34bf9884-4424-4d5c-9db2-dc814c56d181',
    name: 'Santiago D.',
    startDate: '2022-03-01T00:00:00.000Z',
    endDate: '2022-03-07T00:00:00.000Z',

    score: 643,
    timeSpent: '01:10:50',
    failedAnswers: 0,
    progress: '50'
  },
  {
    id: '3a5c6681-d3c8-411e-9db9-aef1fcd1f158',
    name: 'Lucas T.',
    startDate: '2022-02-21T00:00:00.000Z',
    endDate: '2022-02-27T00:00:00.000Z',

    score: 462,
    timeSpent: '01:10:50',
    failedAnswers: 2,
    progress: '50'
  },
  {
    id: '4a2c9ffc-b5a4-4f3e-a8a0-7da433d117e3',
    name: 'Sean V.',
    startDate: '2022-02-01T00:00:00.000Z',
    endDate: '2022-02-08T00:00:00.000Z',

    score: 444,
    timeSpent: '00:05:21',
    failedAnswers: 8,
    progress: '50'
  },
  {
    id: '581e232d-c430-4961-bc14-df153f227b54',
    name: 'Alexandre N.',
    startDate: '2022-01-21T00:00:00.000Z',
    endDate: '2022-01-28T00:00:00.000Z',

    score: 447,
    timeSpent: '00:05:21',
    failedAnswers: 3,
    progress: '50'
  },
  {
    id: '5ed78dea-08ea-409c-9c17-9ede1d7071df',
    name: 'Alexander P.',
    startDate: '2022-01-11T00:00:00.000Z',
    endDate: '2022-02-18T00:00:00.000Z',
    score: 535,
    timeSpent: '00:05:21',
    failedAnswers: 12,
    progress: '50'
  },
  {
    id: '5f3668d3-c3cb-47e9-a98c-660129a81358',
    name: 'Gustavo G.',
    startDate: '2022-03-11T00:00:00.000Z',
    endDate: '2022-03-18T00:00:00.000Z',

    score: 586,
    timeSpent: '00:05:21',
    failedAnswers: 8,
    progress: '50'
  },
  {
    id: '60493418-31e7-442d-9189-89f7651a9c73',
    name: 'Naruto X.',
    startDate: '2022-03-01T00:00:00.000Z',
    endDate: '2022-03-07T00:00:00.000Z',

    score: 647,
    timeSpent: '00:05:21',
    failedAnswers: 7,
    progress: '50'
  },
  {
    id: '612c9e98-8f6f-4099-8b95-4ec7dc01b539',
    name: 'Sasuke U.',
    startDate: '2022-01-01T00:00:00.000Z',
    endDate: '2022-01-08T00:00:00.000Z',

    score: 832,
    timeSpent: '00:05:21',
    failedAnswers: 21,
    progress: '50'
  },
  {
    id: '783f6c7a-aa13-4a29-bd5c-dbbcf0d6ef37',
    name: 'Addition training',
    startDate: '2021-12-12T00:00:00.000Z',
    endDate: '2021-12-20T00:00:00.000Z',
    score: 761,
    timeSpent: '00:05:21',
    failedAnswers: 13,
    progress: '50'
  },
  {
    id: '98971ef8-eed0-4537-9fb4-e09cc73f7737',
    name: 'Weekend homework',
    startDate: '2021-12-01T00:00:00.000Z',
    endDate: '2021-12-08T00:00:00.000Z',
    score: 713,
    timeSpent: '00:05:21',
    failedAnswers: 15,
    progress: '50'
  }
]

export const SELECTED_ASSIGNMENT_BY_STUDENT_RESUME = {
  startDate: '2022-03-21T00:00:00.000Z',
  endDate: '2022-03-21T00:00:00.000Z',
  totalScore: 1,
  totalTimeSpent: '00:00:00',
  totalFailedAnswers: 10,
  progress: '50',
  games: [
    {
      title: 'Access shaft',
      failedAnswers: 1,
      progress: '50',
      totalScore: 292,
      totalTimeSpent: '00:00:00'
    },
    {
      title: 'Access shaft',
      failedAnswers: 1,
      progress: '50',
      totalScore: 292,
      totalTimeSpent: '00:00:00'
    },
    {
      title: 'Access shaft',
      failedAnswers: 1,
      progress: '50',
      totalScore: 292,
      totalTimeSpent: '00:00:00'
    },
    {
      title: 'Access shaft',
      failedAnswers: 1,
      progress: '50',
      totalScore: 292,
      totalTimeSpent: '00:00:00'
    },
    {
      title: 'Access shaft',
      failedAnswers: 1,
      progress: '50',
      totalScore: 292,
      totalTimeSpent: '00:00:00'
    }
  ]
}

export const CUSTOMER_OVERVIEW_LIST = [
  {
    id: 100001,
    dealType: 'Municipality',
    municipality: 'Oslo',
    dealOwner: 'Thomas Bargen',
    numberOfLicenses: 10,
    dealValue: 374930,
    licenseStatus: 'active',
    invoiceStatus: 'paid' as InvoiceStatusTypes
  },
  {
    id: 110002,
    dealType: 'Municipality',
    municipality: 'Bargen',
    dealOwner: 'Lisa Baar',
    numberOfLicenses: 210,
    dealValue: 344930,
    licenseStatus: 'cancelled',
    invoiceStatus: 'not sent' as InvoiceStatusTypes
  },
  {
    id: 10003,
    dealType: 'School',
    municipality: 'Sogdnal',
    dealOwner: 'Linda Lek',
    numberOfLicenses: 2120,
    dealValue: 1220392,
    licenseStatus: 'in cancelling',
    invoiceStatus: 'not paid' as InvoiceStatusTypes
  },
  {
    id: 10004,
    dealType: 'School',
    municipality: 'Sogdnal',
    dealOwner: 'Linda Lek',
    numberOfLicenses: 2120,
    dealValue: 1220392,
    licenseStatus: 'in cancelling',
    invoiceStatus: 'sent' as InvoiceStatusTypes
  },
  {
    id: 10005,
    dealType: 'Municipality',
    municipality: 'Bargen',
    dealOwner: 'Lisa Baar',
    numberOfLicenses: 210,
    dealValue: 344930,
    licenseStatus: 'cancelled',
    invoiceStatus: 'not sent' as InvoiceStatusTypes
  },
  {
    id: 1000012,
    dealType: 'Municipality',
    municipality: 'Oslo',
    dealOwner: 'Thomas Bargen',
    numberOfLicenses: 10,
    dealValue: 374930,
    licenseStatus: 'active',
    invoiceStatus: 'paid' as InvoiceStatusTypes
  },
  {
    id: 1100202,
    dealType: 'Municipality',
    municipality: 'Bargen',
    dealOwner: 'Lisa Baar',
    numberOfLicenses: 210,
    dealValue: 344930,
    licenseStatus: 'cancelled',
    invoiceStatus: 'not sent' as InvoiceStatusTypes
  },
  {
    id: 120003,
    dealType: 'School',
    municipality: 'Sogdnal',
    dealOwner: 'Linda Lek',
    numberOfLicenses: 2120,
    dealValue: 1220392,
    licenseStatus: 'in cancelling',
    invoiceStatus: 'not paid' as InvoiceStatusTypes
  },
  {
    id: 102004,
    dealType: 'School',
    municipality: 'Sogdnal',
    dealOwner: 'Linda Lek',
    numberOfLicenses: 2120,
    dealValue: 1220392,
    licenseStatus: 'in cancelling',
    invoiceStatus: 'sent' as InvoiceStatusTypes
  },
  {
    id: 102005,
    dealType: 'Municipality',
    municipality: 'Bargen',
    dealOwner: 'Lisa Baar',
    numberOfLicenses: 210,
    dealValue: 344930,
    licenseStatus: 'cancelled',
    invoiceStatus: 'not sent' as InvoiceStatusTypes
  },
  {
    id: 11004,
    dealType: 'School',
    municipality: 'Sogdnal',
    dealOwner: 'Linda Lek',
    numberOfLicenses: 2120,
    dealValue: 1220392,
    licenseStatus: 'in cancelling',
    invoiceStatus: 'sent' as InvoiceStatusTypes
  },
  {
    id: 13005,
    dealType: 'Municipality',
    municipality: 'Bargen',
    dealOwner: 'Lisa Baar',
    numberOfLicenses: 210,
    dealValue: 344930,
    licenseStatus: 'cancelled',
    invoiceStatus: 'not sent' as InvoiceStatusTypes
  }
]
