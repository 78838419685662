import { api, ApiResponseTypes } from '../../api'
import { PlanetTypes } from './Planets.types'

type _OrderedPlanetTypes = PlanetTypes & {
  order: number
}

const getAllPlanets = async () => {
  const { data: response } =
    await api.get<ApiResponseTypes<_OrderedPlanetTypes[]>>('/planets')

  return response.data
}

export default {
  get: getAllPlanets
}
