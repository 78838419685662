import { ErrorBoundary } from '@sentry/react'
import { useAuth, useLayout } from 'context'
import { AnimatePresence } from 'framer-motion'
import { DashboardLayout } from 'layout'
import React, { Suspense } from 'react'
import { createBrowserHistory as getBrowserHistoryCompatibility } from '@remix-run/router'
import { createBrowserHistory } from 'history'
import {
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom'

import {
  BackdropComponent,
  renderAssignmentRoutes,
  renderCreateAssignmentRoutes,
  renderCustomerOverviewRoutes,
  renderLicenseRoutes,
  renderLoginRoutes,
  renderLogoutRoutes,
  renderOfflineContentRoutes,
  renderActivityBankRoutes,
  renderGroupRoutes,
  renderLibraryRoutes,
  renderSupportRoutes,
  renderPdfGeneratorRoutes,
  renderReportsRoutes,
  renderGenericRoutes,
  ROUTES as PATH_ROUTES
} from 'router'
import { ROLE } from 'services'

export const Router: React.FC = () => {
  const browserHistory = createBrowserHistory({ window })

  const browserHistoryV5Compatibility = getBrowserHistoryCompatibility({
    v5Compat: true
  })

  const { BASE_ROOT, ASSIGNMENT, AUTH, LOGIN, SALES_TEAM, UNKNOWN } =
    PATH_ROUTES

  const { isPageLoading } = useLayout()

  const { user, hasCookie } = useAuth()

  return (
    <AnimatePresence exitBeforeEnter>
      <Suspense fallback={<BackdropComponent open />}>
        <ErrorBoundary>
          <BackdropComponent open={isPageLoading} />
          <HistoryRouter
            history={{ ...browserHistory, ...browserHistoryV5Compatibility }}
          >
            <Routes>
              {hasCookie === false && (
                <>
                  <Route
                    index
                    element={<Navigate to={`${AUTH}/${LOGIN}`} replace />}
                  />
                  <Route
                    path={UNKNOWN}
                    element={<Navigate to={BASE_ROOT} replace />}
                  />
                </>
              )}
              <Route path={AUTH} element={<Outlet />}>
                <Route index element={<Navigate to={LOGIN} replace />} />
                {renderLoginRoutes()}
                {renderLogoutRoutes()}
              </Route>
              {user?.roles.find((role) => role === ROLE.TEACHER) && (
                <>
                  <Route path={BASE_ROOT} element={<DashboardLayout />}>
                    <Route index element={<Navigate to={ASSIGNMENT} />} />
                    {renderAssignmentRoutes()}
                    {renderLibraryRoutes()}
                    {renderCreateAssignmentRoutes()}
                    {renderLicenseRoutes()}
                    {renderReportsRoutes()}
                    {renderGroupRoutes()}
                    {renderOfflineContentRoutes()}
                    {renderPdfGeneratorRoutes()}
                    {renderActivityBankRoutes()}
                    {renderSupportRoutes()}
                    {renderGenericRoutes()}
                  </Route>
                  <Route path={UNKNOWN} element={<Navigate to={BASE_ROOT} />} />
                </>
              )}

              {user?.roles.find((role) => role === ROLE.SALES_TEAM) && (
                <>
                  <Route
                    path={BASE_ROOT}
                    element={<DashboardLayout isAsideHidden />}
                  >
                    <Route index element={<Navigate to={SALES_TEAM} />} />
                    {renderCustomerOverviewRoutes()}
                    {renderGenericRoutes()}
                  </Route>
                  <Route path={UNKNOWN} element={<Navigate to={BASE_ROOT} />} />
                </>
              )}
            </Routes>
          </HistoryRouter>
        </ErrorBoundary>
      </Suspense>
    </AnimatePresence>
  )
}
