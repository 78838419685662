export default {
  minute: 'minute',
  minutes: 'minutes',
  min: 'min',

  showingOf: 'Showing {{ visibleRows }} of {{ totalDataRows }}',
  showLess: 'Show less',
  showMore: 'Show more',

  invalid: 'Invalid',

  fileSelect: 'Select a file',

  userNotFound: 'User not found',
  noMoreResults: 'No more results',
  loadMoreResults: 'Load more results',
  teacher: 'Teacher',
  yes: 'Yes',
  no: 'No',

  assignment_one: 'Assignment',
  assignment_other: 'Assignments',

  entry_one: 'Entry',
  entry_other: 'Entries',

  group_one: 'Group',
  group_other: 'Groups',

  somethingAndAnotherThing: '{{ something }} and {{ anotherThing }}',

  feideFetchStatus: {
    title: 'Feide synchronization...',
    fetching: 'Updating database with feide information',
    error: 'An error ocurred when trying to fetch information from Feide',
    success: 'Student update from Feide was successful'
  },

  filters: {
    text: {
      filterBy: 'Filter by:',
      filterResults: 'Filter Results',
      clearFilters: 'Clear filters',
      selectItem: 'Select a {{ topic }}'
    },
    status: {
      title: 'Status',
      item: {
        allAssignments: 'all assignments',
        progress: 'active',
        upcoming: 'planned',
        finished: 'completed'
      }
    },
    topics: {
      title: 'Topics'
    },
    subjects: {
      title: 'Subjects',
      item: {
        rounding: 'Rounding',
        totalArea: 'Total Area',
        numberFriends: 'Make a Ten',
        placeValue: 'Place Value',
        multiplication: 'Multiplication',
        probability: 'Probability',
        division: 'Division',
        fraction: 'Fraction',
        additionAndSubtraction: 'Addition and subtraction',
        negativeNumbers: 'Negative numbers',
        fractionWithClock: 'Fraction with clock',
        fractionOfANumber: 'Fraction of a number',
        fractionBasic: 'Fraction, basic',
        fractionMixed: 'Fraction, like and mixed numbers',
        fractionDecimals: 'Fraction, decimal numbers, percent',
        codingFunctions: 'Coding, functions, variables',
        mathematicalConcepts: 'Mathematical concepts',
        fourArithmeticOperations: 'The four arithmetic operations',
        logicalThinking: 'Logical thinking, The four arithmetic operations',
        multiplicationTable: 'Multiplication table',
        algebra: 'Algebra',
        creativeWriting: 'Creative writing',
        reading: 'Reading',
        coloring: 'Coloring',
        observationSkills: 'Observation skills, Problemsolving',
        logicalThinkingFilter: 'Logical thinking',
        observationSkillsFilter: 'Observation skills',
        problemsolvingFilter: 'Problemsolving',
        brokAlgebraEtc:
          'Fractions, algebra, clock, the four arithmetic operations, probability',
        brokFilterCode: 'Brøk',
        algebraFilterCode: 'algebra',
        klokkaFilterCode: 'klokka',
        regnearterFilterCode: 'de fire regnearter',
        sannsynlighetFilterCode: 'sannsynlighet',
        codeCruncher: 'Code Cruncher'
      }
    },
    assigned: {
      title: 'Assigned to',
      item: {
        students: 'students',
        groups: 'groups'
      }
    },
    planets: {
      title: 'Planets'
    },
    skills: {
      title: 'Skills'
    },
    groups: {
      title: 'All Groups'
    }
  },
  imageAlt: {
    login: {
      gameCharacters: 'Game characters'
    }
  },
  status: {
    error: {
      notFound: {
        title: `Woops! <br/> We couldn't find the page you are looking for.`,
        description:
          'The site might not exist anymore, or the contents have been moved. <customLink>Go to the main page</customLink> or try to choose a page from the main menu.'
      },
      somethingWentWrong: 'Something went wrong',
      cannotUndoThisAction: 'You <strong>cannot</strong> undo this action.'
    },
    active: 'Active',
    inactive: 'Inactive',
    premium: 'Premium',
    freemium: 'Freemium',
    payment: {
      paid: 'Paid',
      notPaid: 'Not paid',
      sent: 'Sent',
      notSent: 'Not sent'
    }
  },
  state: {
    nrOfModesSelected_0: 'No modes selected',
    nrOfModesSelected: '{{ count  }} Mode selected',
    nrOfModesSelected_plural: '{{ count }} Modes selected',
    selected: 'Selected',
    notSelected: 'Not Selected',
    selectedNumber: '{{ selectedNumber }} selected',
    removed: 'Removed',
    deleted: 'Deleted',
    modified: 'Modified',
    started: 'Started',
    ended: 'Ended',
    comingSoon: 'Coming soon',
    quitEditing: 'Quit editing?',
    searchResultsFor: 'Search results for {{ value }}',
    loading: 'Loading...',
    studentAdded: 'Student Added',
    ok: 'Ok'
  },
  selectAll: 'Select all',
  actions: {
    viewReport: 'View reports',
    add: 'Add',
    addAndClose: 'Add and close',
    addAndContinue: 'Add and continue',
    addNewTopic: 'Add new topic',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
    remove: 'Remove',
    next: 'Next',
    back: 'Back',
    createGroup: 'Create group',
    saveGroup: 'Save group',
    save: 'Save {{ addOn }}',
    saveChanges: 'Save changes',
    saveCustomer: 'Save customer',
    update: 'Update {{ addOn }}',
    select: 'Select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    report: 'Report',
    editParentsName: 'Edit parents name',
    moveToGroup: 'Move to a group',
    revoke: 'Revoke',
    moreOptions: 'More Options',
    close: 'Close',
    goToArticle: 'Go to article',
    keepEditing: 'Keep editing',
    quit: 'Yes, quit',
    show: 'Show',
    hide: 'Hide',
    unHide: 'Unhide'
  },
  user: {
    accountSettings: 'Account settings'
  },
  errors: {
    invalidDate: 'Invalid period',
    invalidTime: 'Invalid Time',
    shouldNotBeEmpty: 'Should not be empty',
    shouldNotBeEmptyAddon: '{{ key }} should not be empty',
    unexpectedError: 'Unexpected Error',
    notSaved: `Changes you've made so far will <strong>not</strong> be saved.`
  },
  validation: {
    required: 'This is a required field'
  },
  dealTypes: {
    municipality: 'Municipality',
    school: 'School'
  }
}
