import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { ColorsTypes } from 'styles/tools/colors'
import { Colors, FontWeights } from 'styles'
import { AlignTypes } from 'components/popup/PopUpModal.types'

export const StyledImage = styled.img`
  width: 16rem;
  height: 16rem;
  margin-bottom: 1.6rem;
  object-fit: cover;
`

export const ResponseModalStyles = styled(Box)<{
  transColor?: keyof ColorsTypes
  align?: AlignTypes
  textColor?: keyof ColorsTypes
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({ transColor = 'info' }) => css`
    p {
      strong {
        font-weight: ${FontWeights.bold};
        color: ${Colors[transColor]};
      }
    }
  `}
`
