import React, { forwardRef, PropsWithChildren } from 'react'

import { LoadingButton } from '@mui/lab'
import { ButtonPropTypes } from './Button.types'

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonPropTypes>
>(({ value, children = value, ...props }, ref) => {
  const { fontSize, fontWeight, ...rest } = props

  return (
    <LoadingButton
      ref={ref}
      sx={{
        fontWeight,
        fontSize,
        ...rest.sx
      }}
      {...rest}
    >
      <span>{children}</span>
    </LoadingButton>
  )
})

export * from './Button.types'
