import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { PostUserGroupTypes, UserGroupTypes } from './UserGroups.types'

const getAllUserGroups = async () => {
  const { data: response } =
    await api.get<ApiResponseTypes<UserGroupTypes[]>>('/user-groups')

  return response.data
}

const postUserGroup = async (group: PostUserGroupTypes) => {
  return (await api.post<UserGroupTypes>('/user-groups', group)).data
}

export default {
  get: getAllUserGroups,
  post: postUserGroup
}
