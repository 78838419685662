import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'

export const withTransition = (
  children: JSX.Element,
  key?: string,
  animation?: HTMLMotionProps<'div'>
) => (
  <motion.span
    key={key}
    initial={{
      opacity: 0
    }}
    style={{ height: '100%', width: '100%' }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    {...animation}
  >
    {children}
  </motion.span>
)
