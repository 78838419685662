import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { item } from './Item.styles'
import { PDFItemPropTypes } from './Item.types'
import { VerticalItem } from './components'

export const PDFItemComponent: React.FC<PDFItemPropTypes> = ({
  exercise,
  index,
  options,
  isAnswerSheet
}) => {
  const styleNumberOfItemsPerSheet = {
    6: () => item.sixItems,
    25: () => item.twentyFiveItems,
    50: () => item.fiftyItems
  }

  const maxDigits = () => {
    return (
      options.factors.first.to.toString().length +
      options.factors.second.to.toString().length
    )
  }

  const shouldShrinkFont = () => {
    return (
      options.tasksPerSheet >= 25 &&
      !options.verticalOperation &&
      maxDigits() > 7
    )
  }

  const getFontSize = () => {
    let digits = maxDigits()
    // when orders are randomized, equation may have extra digits. example: 5 + ___ = 10. in this case max digits should be 3 not 2. this effect is stronger the bigger the diff between factors
    if (options.randomizeMissingFactorProduct) {
      digits +=
        1 +
        Math.abs(
          options.factors.first.to.toString().length -
            options.factors.second.to.toString().length
        )
    }
    if (digits === 8) {
      return 10.5
    }
    if (digits === 9) {
      return 9.5
    }
    // min size = 6 for digits > 14
    // each length > 9 reduces font size by 0.5, starting from 8.5 for 10 digits
    return Math.max(6, -0.5 * digits + 13.5)
  }

  const styleFontSize = !shouldShrinkFont()
    ? {}
    : {
        fontSize: getFontSize()
      }

  return (
    <View
      style={[
        item.default,
        styleNumberOfItemsPerSheet[options.tasksPerSheet]()
      ]}
    >
      <View style={item.pagination}>
        <Text style={item.paginationText}>{index + 1}</Text>
      </View>
      <View style={item.exercise}>
        styleFontSize
        <View style={{ ...item.exerciseItem, ...styleFontSize }}>
          {options?.verticalOperation ? (
            <VerticalItem
              exercise={exercise}
              options={options}
              hideResult={!isAnswerSheet}
            />
          ) : (
            exercise.map(({ value, shouldBeMissed }) => {
              if (!isAnswerSheet && shouldBeMissed) {
                return <View style={item.nullElement} />
              }

              if (shouldBeMissed) {
                return <Text style={item.answerElement}>{value}</Text>
              }

              return <Text style={item.exerciseText}>{value}</Text>
            })
          )}
        </View>
      </View>
    </View>
  )
}
